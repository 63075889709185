import React, {Component} from 'react';
import {withAlert} from "react-alert";
import Services from "../components/portal/Services";
import Promotion from '../components/portal/Promotion';
import Blogs from "../components/portal/Blogs";
import Branches from "../components/portal/Branches";
import Crew from "../components/portal/Crew";
import Testimonials from "../components/portal/Testimonials";
import Awards from "../components/portal/Awards";
import '../assets/css/Admin.css';



class Admin extends Component {
    componentDidMount() {
        document.getElementsByClassName('fixed-top')[0].style.backgroundColor = "#111111";
    }

    componentWillUnmount() {
        document.getElementsByClassName('fixed-top')[0].style.backgroundColor = "transparent";
    }

    render() {
        return (

            <div className="header-container admin-container">
                <div className="admin-container-navbar">
                    <div className="vertical-nav nunito-sans-font">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" style={{marginTop: "4%"}}>

                            <a className="nav-link active" id="v-pills-services-tab" data-toggle="pill"
                               href="#v-pills-services" role="tab" aria-controls="v-pills-services"
                               aria-selected="true">Services</a>

                            <a className="nav-link" id="v-pills-promotion-tab" data-toggle="pill"
                               href="#v-pills-promotion" role="tab" aria-controls="v-pills-promotion"
                               aria-selected="true">Promotion</a>

                            <a className="nav-link" id="v-pills-awards-tab" data-toggle="pill"
                               href="#v-pills-awards" role="tab" aria-controls="v-pills-awards"
                               aria-selected="true">Awards</a>

                            <a className="nav-link" id="v-pills-blogs-tab" data-toggle="pill"
                               href="#v-pills-blogs" role="tab" aria-controls="v-pills-blogs"
                               aria-selected="true">Blogs</a>

                            <a className="nav-link" id="v-pills-branches-tab" data-toggle="pill"
                               href="#v-pills-branches" role="tab" aria-controls="v-pills-branches"
                               aria-selected="true">Branches</a>

                            <a className="nav-link" id="v-pills-crews-tab" data-toggle="pill"
                               href="#v-pills-crews" role="tab" aria-controls="v-pills-crews"
                               aria-selected="true">Crew Members</a>

                            <a className="nav-link" id="v-pills-testimonials-tab" data-toggle="pill"
                               href="#v-pills-testimonials" role="tab" aria-controls="v-pills-testimonials"
                               aria-selected="true">Testimonials</a>
                        </div>
                    </div>
                    <div className="nav-detail-container">
                        <div className="tab-content admin-tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-services" role="tabpanel"
                                 aria-labelledby="v-pills-services-tab">
                                <Services/>
                            </div>

                            <div className="tab-pane fade" id="v-pills-promotion" role="tabpanel"
                                 aria-labelledby="v-pills-promotion-tab">
                                <Promotion/>
                            </div>

                            <div className="tab-pane fade" id="v-pills-awards" role="tabpanel"
                                 aria-labelledby="v-pills-awards-tab">
                                <Awards/>
                            </div>

                            <div className="tab-pane fade" id="v-pills-blogs" role="tabpanel"
                                 aria-labelledby="v-pills-blogs-tab">
                                <Blogs/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-branches" role="tabpanel"
                                 aria-labelledby="v-pills-branches-tab">
                                <Branches/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-crews" role="tabpanel"
                                 aria-labelledby="v-pills-crews-tab">
                                <Crew/>
                            </div>
                            <div className="tab-pane fade" id="v-pills-testimonials" role="tabpanel"
                                 aria-labelledby="v-pills-testimonials-tab">
                                <Testimonials/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        )
    }
}

export default withAlert()(Admin);
