import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withAlert } from "react-alert";
import { getUser } from "../../auth/Auth";
import { handleDeleteAllCheckBox, handleDeleteCheckBox } from "./Common";
import { deleteRequest, uploadFile } from "../../routes/Routes";
import { loadAwards } from "../../common/Common";

class Awards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            action: "",
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            awards: [],
            deleteAwards: [],
            selectedAward: null,
            headerImage: null,
            backgroundImage: null,
        };
        this.headerImageInput = React.createRef();
        this.backgroundImageInput = React.createRef();
    }

    componentDidMount() {
        getUser(this.props.history).then(data => {
            this.setState({ user: data });
        }).catch(() => null);
        loadAwards().then(data => {
            this.setState({ awards: data });
        }).catch(() => null);
    }

    render() {
        let {
            user,
            loading,
            action,
            error,
            errorMessage,
            success,
            successMessage,
            awards,
            deleteAwards,
            selectedAward,
            headerImage,
            backgroundImage,
        } = this.state;

        return (
            <div>
                {action === 'Add New Award' || action === 'Edit Award' ?
                    <form onSubmit={this.formSubmit} className="admin-form">
                        <h1 className="heading admin-heading">{action}</h1>
                        <div className="form-row" style={{ marginBottom: '2%' }}>
                            <div className="form-group col-md-4">
                                <label htmlFor="name" className="form-label">Award Name</label>
                                {selectedAward ?
                                    <input className="form-control" type="text" placeholder='Award Name' id="name"
                                        required
                                        value={selectedAward.name}
                                        onChange={(event) => this.handleChange(event, 'name')} />
                                    :
                                    <input className="form-control" type="text" placeholder='Award Name' id="name"
                                        required />
                                }
                            </div>
                        </div>
                        <div className='form-row' style={{ gap: '2%' }}>
                            <div className="custom-file form-group col-md-4" style={{ marginBottom: '2%' }}>
                                {headerImage ?
                                    <img src={URL.createObjectURL(headerImage)} alt='Header Image'
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'fill',
                                        }} />
                                    : selectedAward ?
                                        <img src={selectedAward.header_image_url} alt='Header Image'
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'fill',
                                            }} />
                                        : null
                                }
                                <input type="file" className="custom-file-input" id="awardHeaderImage"
                                    accept="image/*"
                                    ref={this.headerImageInput}
                                    onChange={(event) => this.handleChange(this.headerImageInput, 'headerImage')} />
                                <label className="custom-file-label" htmlFor="awardHeaderImage">
                                    <small id="awardHeaderImage" className="form-text text-muted"
                                        style={{ marginBottom: '2%', textAlign: 'center' }}>
                                        Add Award Image
                                    </small>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"
                                        alt="Add Icon">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
                                    </svg>
                                    <div className="image-uploader-label">
                                        Drag & drop or select from your files
                                    </div>
                                </label>
                            </div>

                            <div className="custom-file form-group col-md-6" style={{ marginBottom: '2%' }}>
                                {backgroundImage ?
                                    <img src={URL.createObjectURL(backgroundImage)} alt='Background Image'
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'fill',
                                        }} />
                                    : selectedAward ?
                                        <img src={selectedAward.background_image_url}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'fill',
                                            }} alt='Background Image' />
                                        : null
                                }
                                <input type="file" className="custom-file-input" id="awardBackgroundImage"
                                    accept="image/*"
                                    ref={this.backgroundImageInput}
                                    onChange={(event) => this.handleChange(this.backgroundImageInput, 'backgroundImage')} />
                                <label className="custom-file-label" htmlFor="awardBackgroundImage">
                                    <small id="awardBackgroundImage" className="form-text text-muted"
                                        style={{ marginBottom: '2%', textAlign: 'center' }}>
                                        Add Award background Image
                                    </small>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48"
                                        alt="Add Icon">
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                            d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993v9.349A5.99 5.99 0 0 0 20 13V5H4l.001 14 9.292-9.293a.999.999 0 0 1 1.32-.084l.093.085 3.546 3.55a6.003 6.003 0 0 0-3.91 7.743L2.992 21A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z" />
                                    </svg>
                                    <div className="image-uploader-label">
                                        Drag & drop or select from your files
                                    </div>
                                </label>
                            </div>
                        </div>
                        {success &&
                            <div className="alert alert-success" role="alert">
                                {successMessage}
                            </div>}
                        {error &&
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>}
                        <div className="form-row">
                            <button className="btn white-button form-button col mr-3" style={{ marginRight: '5%' }} id=""
                                onClick={this.actionClick}>
                                Cancel
                            </button>
                            <button className="btn red-button form-button col" >
                                {loading ?
                                    <span className="spinner-grow spinner-grow-sm ml-1" role="status"
                                        aria-hidden="true" />
                                    : null
                                }
                                {action === 'Add New Award' ? 'Add' : 'Save'}
                            </button>
                        </div>
                    </form>
                    : [
                        <h1 className="heading admin-heading" key="heading">Awards</h1>,
                        <div key="award-delete" className="action-container">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id={'awardSelectAll'}
                                    checked={awards.length === deleteAwards.length}
                                    onChange={() => this.handleDeleteAllCheckBox()} />
                                <label className="custom-control-label" htmlFor={'awardSelectAll'}>Select All</label>
                            </div>
                            <div className="action-button-container">
                                <button className="btn gold-border-button" onClick={this.delete}
                                    disabled={deleteAwards.length === 0}>
                                    Delete
                                </button>
                                <button className="btn gold-button" id="Add New Award"
                                    onClick={this.actionClick}>
                                    Add
                                </button>
                            </div>
                        </div>,
                        <table className="w-100 table-hover admin-table" key="award-table">
                            <thead>
                                <tr>
                                    <th />
                                    <th>No</th>
                                    <th>Name</th>
                                    <th>Header Image</th>
                                    <th>Background Image</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {this.tableBody()}
                            </tbody>
                        </table>,
                    ]}
            </div>
        );
    };

    actionClick = (event, award = null) => {
        if (!award) event.preventDefault();
        this.setState({
            action: award ? event : event.target.id,
            selectedAward: award,
            error: false,
            errorMessage: null,
            success: false,
            successMessage: null,
            headerImage: null,
            backgroundImage: null,
        });
    };

    formSubmit = async (event) => {
        let { user, awards, action, selectedAward } = this.state,
            endpoint;
        event.preventDefault();
        const data = new FormData();
        if (action === 'Add New Award') {
            data.append('name', event.target.name.value);
            if (this.headerImageInput.current.files.length > 0) {
                data.append('header_image', this.headerImageInput.current.files[0]);
            }
            if (this.backgroundImageInput.current.files.length > 0) {
                data.append('background_image', this.backgroundImageInput.current.files[0]);
            }
            endpoint = '/api/awards';
        } else {
            data.append('name', selectedAward.name);
            if (selectedAward.headerImage) {
                data.append('header_image', selectedAward.headerImage);
            }
            if (this.backgroundImageInput.current.files.length > 0) {
                data.append('background_image', this.backgroundImageInput.current.files[0]);
            }
            endpoint = '/api/awards/update/' + selectedAward.id;
        }

        uploadFile(data, endpoint, user.api_token).then(response => {
            if (response.status === 200) {
                if (response.data.error) {
                    this.setState({
                        error: true,
                        errorMessage: response.data.message,
                        success: false,
                        successMessage: null,
                    });
                } else {
                    if (action === 'Add New Award') {
                        awards.push(response.data.award);
                    } else {
                        awards[awards.indexOf(selectedAward)] = response.data.award;
                    }
                    this.setState({
                        error: false,
                        errorMessage: null,
                        success: true,
                        successMessage: response.data.message,
                        awards: awards,
                    });
                }
            } else {
                this.setState({ error: true, errorMessage: 'An error occurred!' });
            }
            this.setState({ loading: false });
        });
    }

    tableBody = () => {
        let body = [],
            { awards, deleteAwards } = this.state;
        if (awards) {
            awards.forEach((award, index) => {
                body.push(
                    <tr key={index}>
                        <td>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input"
                                    id={'awardDelete' + award.id}
                                    checked={deleteAwards.includes(award.id)}
                                    onChange={(event) => this.handleDeleteCheckBox(award.id)} />
                                <label className="custom-control-label" htmlFor={'awardDelete' + award.id} />
                            </div>
                        </td>
                        <td>{index + 1}</td>
                        <td>{award.name}</td>
                        <td className="image-column" style={{ display:'flex',alignItems: 'center' }}>
                            {award.header_image_url ?
                                <img src={award.header_image_url} alt="Header Image"
                                    style={{ width: '65px', height: '65px' }} />
                                : null
                            }
                        </td>
                        <td>
                            {award.background_image_url ?
                                <img src={award.background_image_url} alt="Background Image"
                                    style={{ width: '150px', height: '65px' }} />
                                : null
                            }
                        </td>
                        <td className="action-column">
                            <span onClick={() => this.actionClick('Edit Award', award)}>Edit</span>
                        </td>
                    </tr>
                )
            });
        }
        return body;
    }

    handleDeleteCheckBox = async (id) => {
        let { deleteAwards } = this.state;
        this.setState({ deleteAwards: handleDeleteCheckBox(id, deleteAwards) });
    };

    handleDeleteAllCheckBox() {
        let { deleteAwards, awards } = this.state;
        this.setState({ deleteAwards: handleDeleteAllCheckBox(deleteAwards, awards) });
    }

    delete = () => {
        let { user, awards, deleteAwards } = this.state;
        deleteAwards.forEach((award, index) => {
            deleteRequest('/api/awards/' + award, user.api_token).then(response => {
                if (response.status === 200) {
                    if (response.data.error) {
                        this.props.alert.error(response.data.message);
                    } else {
                        if (deleteAwards.length === index + 1) {
                            this.setState({
                                awards: awards.filter(function (award) {
                                    return !deleteAwards.includes(award.id);
                                }),
                                deleteAwards: []
                            });
                            this.props.alert.success("Successfully deleted Awards");
                        }
                    }
                } else {
                    this.props.alert.error("An error occurred!");
                }
            });
        });
    }

    handleChange(event, key) {
        let { selectedAward } = this.state;
        const file = key === 'headerImage' || 'backgroundImage' ? event.current.files[0] : event.target.files[0];

        if (selectedAward) {
            selectedAward[key] = file;

            this.setState({
                selectedAward: selectedAward,
                [key]: file,
            });
        } else {
            this.setState({
                [key]: file,
            });
        }
    }

}

export default withAlert()(withRouter(Awards));
